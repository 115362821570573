<template>
    <div class="Detailsclassroom clearfix">
        <div class="sroom_left fl">
            <p class="group">
                <!-- <span> -->
                    <el-tooltip class="item" effect="dark" content="返回" placement="bottom">
                        <button class="mu-icon-button" @click="back()">
                            <div class="">
                                <i class="mu-icon material-icons el-icon-arrow-left"></i>
                            </div>
                        </button>
                    </el-tooltip>
                <!-- </span> -->
                小组选择
            </p>
            <ul class="sroom_ul" @mouseover="mouseover"  @mouseout="mouseout()">
                <li class="sroom_ul_li" v-for="(item,index) in teamList" :key="index" :class="istrue==index?'active_li':''" @click="sroom(item,index)">
                    <i class="iconfont icon-flag-fill"></i>
                    <div class="group_div">
                        <p class="group_div_name">{{item.name}}</p>
                        <p class="group_div_content">小组总人数：{{item.unit}}</p>
                        <p class="group_div_content">已加入人数：{{item.users}}</p>
                        <p class="group_div_content">已提交项目数：{{item.pjts}}</p>
                    </div>
                </li>
                <div class="sroom_ul_div" v-show="show">
                    <ul class="item_ul">
                        <li class="item_ul_li" v-for="(item,index) in listitem" :key="index" v-show="listitem.length!=0" :class="istrues==index?'active_itemli':''" @click="srooms(item,index)">
                            {{item.name}}
                        </li>
                        <div class="item_ul_div" v-show="listitem.length==0">
                            <div class="item_ulwrappers" >
                                <img src="./../../assets/img/bookmark.svg" alt="">
                                <p><span>该小组未创建项目</span></p>
                            </div>
                        </div>
                    </ul>
                </div>
            </ul>
        </div>
        <div class="sroom_right fl">
            <div class="sroom_right_top">
                <div class="fl name_fl">
                    {{pro_names}}
                </div>
                <div class="fr">
                    <el-button type="primary" @click="Newadd()" v-show="typeList!=0">项目基本信息</el-button>
                    <el-button type="primary" @click="printBox()" v-show="typeList!=0">打印BP框架</el-button>
                    <el-button type="primary" v-show="inform.state==1" @click="classes()">开 课</el-button>
                    <el-button type="primary" v-show="inform.state==2" @click="session()">结 课</el-button>
                </div>
            </div>
            <div class="sroom_right_content">
                <el-row :gutter="20">
                    <el-col 
                        :xs="24" 
                        :md="12" 
                        :lg="8" 
                        :xl="6"
                        v-for="(item,index) in typeList" :key="index"
                    >
                        <div class="rivon_box">
                            <img class="rivon_box_img" src="./../../assets/img/notation.png" alt="" v-show="item.remark!==''&&item.remark!==null">
                            <div class="rivon_info">
                                <div class="rivon_container">
                                    <h5 class="text-overflow m-t-0">{{item.name}}</h5>
                                </div>
                                <div class="must_info">
                                    <p class="must_muted" v-for="(citem,cindex) in item.fmList" :key="cindex">
                                        <span class="fm-title">{{citem.name}}：</span>{{citem.value}}
                                    </p>
                                </div>
                                <div class="must_data clearfix">
                                    <span class="fl span_data">
                                        {{item.updateTime}}
                                    </span>
                                    <span class="fr">
                                        <el-button size="mini" @click="updateBtn(item)">查看</el-button>
                                        <el-button size="mini" type="primary" @click="exits(item)">点评</el-button>
                                        <!-- <i class="el-icon-edit-outline" @click="dialogVisibleForm=true"></i> -->
                                    </span>
                                </div>
                            </div>
                        </div>
                    </el-col>
                    <el-col 
                        :span="24"
                        v-show="typeList.length==0"
                        class="elcol_col"
                    >
                        <div class="elcol_div">
                            <div class="default-wrappers" >
                                <img src="./../../assets/img/bookmark.svg" alt="">
                                <p><span>各小组未创建项目</span></p>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
        <!-- 项目信息弹窗 -->
        <div class="Details_Pop">
            <el-dialog
                :visible.sync="dialogVisible"
                :show-close="false"
                width="900px"
                :before-close="handleClose">
                <!-- <i class="el-icon-close closei" @click="closei()"></i>  -->
                <ul class="tab_box clearfix">
                    <li class="fl" :class="active==0?'active':''" @click="tabBox(0)">
                        <span>基础信息</span>
                    </li>
                    <li class="fl" :class="active==1?'active':''" @click="tabBox(1)">
                        <span>其它信息</span>
                    </li>
                </ul>
                <el-row :gutter="20">
                    <el-form ref="ruleForm" :model="ruleForm" :rules="rules" v-show="active==0" size="small">
                        <el-col 
                            :span="18" 
                        >
                            <el-form-item label="需求" prop="lyXq">
                                <el-input v-model="ruleForm.lyXq" disabled></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col 
                            :span="6" 
                        >
                            <el-form-item label="打分（满分10）" prop="lyXqScore">
                                <el-input v-model.number="ruleForm.lyXqScore"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col 
                            :span="18" 
                        >
                            <el-form-item label="目标" prop="lyMb">
                                <el-input v-model="ruleForm.lyMb" disabled></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col 
                            :span="6" 
                        >
                            <el-form-item label="打分（满分10）" prop="lyMbScore">
                                <el-input v-model.number="ruleForm.lyMbScore"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col 
                            :span="18" 
                        >
                            <el-form-item label="创新" prop="lyCx">
                                <el-input v-model="ruleForm.lyCx" disabled></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col 
                            :span="6" 
                        >
                            <el-form-item label="打分（满分10）" prop="lyCxScore">
                                <el-input v-model.number="ruleForm.lyCxScore"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col 
                            :span="18" 
                        >
                            <el-form-item label="适配" prop="lySp">
                                <el-input v-model="ruleForm.lySp" disabled></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col 
                            :span="6" 
                        >
                            <el-form-item label="打分（满分10）" prop="lySpScore">
                                <el-input v-model.number="ruleForm.lySpScore"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col 
                            :span="18" 
                        >
                            <el-form-item label="可行" prop="lyKx">
                                <el-input v-model="ruleForm.lyKx" disabled></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col 
                            :span="6" 
                        >
                            <el-form-item label="打分（满分10）" prop="lyKxScore">
                                <el-input v-model.number="ruleForm.lyKxScore"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col 
                            :span="18" 
                        >
                            <el-form-item label="效益" prop="lyXy">
                                <el-input v-model="ruleForm.lyXy"  disabled></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col 
                            :span="6" 
                        >
                            <el-form-item label="打分（满分10）" prop="lyXyScore">
                                <el-input v-model.number="ruleForm.lyXyScore" ></el-input>
                            </el-form-item>
                        </el-col>
                    </el-form>
                    <el-form ref="Forms" :model="Forms" :rules="rules" v-show="active==1" size="small">
                        <el-col 
                            :span="18" 
                        >
                            <el-form-item label="目标市场洞察" prop="sjMbsc">
                                <el-input v-model="Forms.sjMbsc" disabled></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col 
                            :span="6" 
                        >
                            <el-form-item label="打分（满分10）" prop="sjMbscScore">
                                <el-input v-model.number="Forms.sjMbscScore"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col 
                            :span="18" 
                        >
                            <el-form-item label="关键业务设计" prop="sjGjyw">
                                <el-input v-model="Forms.sjGjyw" disabled></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col 
                            :span="6" 
                        >
                            <el-form-item label="请选择类型" prop="sjGjywScore">
                                <el-select v-model="Forms.sjGjywScore">
                                    <el-option label="技术创新" :value="1"></el-option>
                                    <el-option label="边缘创新" :value="2"></el-option>
                                    <el-option label="颠覆创新" :value="3"></el-option>
                                    <el-option label="错位竞争" :value="4"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col 
                            :span="18" 
                        >
                            <el-form-item label="竞品分析（现阶段竞争力）" prop="sjJpfx">
                                <el-input v-model="Forms.sjJpfx" disabled></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col 
                            :span="6" 
                        >
                            <el-form-item label="打分（满分10）" prop="sjJpfxScore">
                                <el-input v-model.number="Forms.sjJpfxScore"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col 
                            :span="18" 
                        >
                            <el-form-item label="团队能力（现阶段支撑力）" prop="sjTdnl">
                                <el-input v-model="Forms.sjTdnl" disabled></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col 
                            :span="6" 
                        >
                            <el-form-item label="打分（满分10）" prop="sjTdnlScore">
                                <el-input v-model.number="Forms.sjTdnlScore"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-form>
                </el-row>
                <span slot="footer" class="dialog-footer">
                    <el-button size="small" @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" size="small" @click="ermine('ruleForm','Forms')">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <!-- 编辑弹窗 -->
        <div class="editor_Popup">
            <el-dialog
                :title="coverName"
                :visible.sync="innerVisible"
                :show-close="false"
                width="600px"
                :before-close="handleClose">
                <i class="el-icon-close closei" @click="closei()"></i>
                <el-form ref="coForm" :model="coForm" :rules="rules">
                    <el-form-item  prop="remark">
                        <el-input type="textarea" v-model="coForm.remark" placeholder="请输入评语内容" rows="6"></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" size="small" @click="comments('coForm')">确 定</el-button>
                </span>
            </el-dialog>
            <el-dialog
                :title="coverName"
                :visible.sync="dialogVisibleForm"
                :show-close="false"
                width="60%"
                :before-close="handleClose">
                    <i class="el-icon-close closei" @click="closei('Form')"></i>
                    <el-row :gutter="40">
                        <el-form ref="Form" :model="Form" :rules="rules">
                            <el-col 
                                :xs="24" 
                                :md="8"
                                v-for="(item,index) in Form.mustInput" :key="index"
                            >
                                <el-form-item :label="item.name">
                                    <el-input v-model="item.content" disabled></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col 
                                :xs="items.flag!=4?24:12" 
                                :md="items.flag!=4?24:12"
                                v-for="(items,indexs) in Form.subProject" :key="'info2-' + indexs"
                                :class="items.flag==4?'img_form':''"
                            >
                                <el-form-item :label="items.name">
                                    <el-input 
                                        :type="items.flag==1?'text':'textarea'" 
                                        v-model="items.content" 
                                        rows="4" 
                                        v-if="items.flag==1 || items.flag==2"
                                        disabled
                                    ></el-input>
                                    <el-upload
                                        v-else
                                        class="avatar-uploader"
                                        :action="uploadimage"
                                        :show-file-list="false"
                                        disabled
                                    >   
                                        <div v-if="items.image" class="avatars">
                                            <div class="avatars_div">
                                                <i class="el-icon-zoom-in" @click="zoomin(items.image)"></i>
                                            </div>
                                            <img :src="items.image" >
                                        </div>
                                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                    </el-upload>
                                </el-form-item>
                                <!-- 大图查看已上传的图片 -->
                                <el-dialog width="600px" :visible.sync="dialogVisibleimg" append-to-body>
                                    <img width="100%" :src="dialogImageUrl" alt="">
                                </el-dialog>
                            </el-col>
                        </el-form>
                    </el-row>
                    <span slot="footer" class="dialog-footer">
                        <el-button type="primary" @click="dialogVisibleForm=false">关 闭</el-button>
                    </span>
            </el-dialog>
        </div>
        <!-- 打印弹窗 -->
        <div class="print_popup">
            <el-dialog
                :visible.sync="dialogPrint"
                :show-close="false"
                width="85%"
                :before-close="handleClose"
            >
                <i class="el-icon-close closeis" @click="closeis()"></i>
                <div class="tab_boxs">
                    <div class="tab_boxs_div">
                        <el-button type="primary" @click="printTemplate()">导出为PDF打印</el-button>
                    </div>
                    <span>
                        打印预览（A3横版）
                        <span>
                            提示：布局为横向、纸张大小为A3、边距为无
                        </span>
                    </span>
                    
                </div>
                <div class="print_div" >
                    <div id="print">
                    <div class="print_main_div"  :class="printm.type==1?'creative':'creatives'">
                        <!-- 学校名称 -->
                        <p class="print_name">{{printm.base.title}}</p>
                        <!-- 项目编号 -->
                        <p class="Itemno">
                            No.{{printm.base.code}}
                        </p>
                        <!-- 项目名称 -->
                        <p class="print_name1">{{printm.base.name}}</p>
                        <!-- 头像1 -->
                        <div v-for="(item,index) in account.slice(0,2)" :key="index">
                            <img :src="item.avatar" alt="" :class="index==0?'image2':index==1?'image1':index==2?'image3':''">
                            <span :class="index==0?'image_span2':index==1?'image_span1':index==2?'image_span3':''">{{item.name}}</span>
                        </div>
                        <!-- 项目类型 -->
                        <p :class="printm.type==1?'p_types':'p_type'">{{printm.base.type}}</p>
                        <!-- 联系电话 -->
                        <p :class="printm.type==1?'p_phones':'p_phone'">{{printm.base.mobile}}</p>
                        <!-- 项目立意 -->
                        <p class="conception">
                            {{printm.xmly}}
                        </p>
                        <!-- 项目logo -->
                        <p class="p_logo">
                            <img :src="printm.logo" alt="">
                        </p>
                        <!-- 项目介绍 -->
                        <p class="introduction">
                            {{printm.xmjs}}
                        </p>
                        <!-- 成立时间 -->
                        <p class="Settime" v-show="printm.type==2">
                            {{printm.qyjj_clsj}}
                        </p>
                        <!-- 企业名称 -->
                        <p class="Anname" v-show="printm.type==2">
                            {{printm.qyjj_qymc}}
                        </p>
                        <!-- 公司理念 -->
                        <p class="philosophy" v-show="printm.type==2">
                            {{printm.qyjj_gsln}}
                        </p>
                        <!-- 核心优势 -->
                        <p class="strengths">
                            {{printm.hxys}}
                        </p>
                        <!-- 项目特点 -->
                        <p class="character">
                            {{printm.jjfa_xmtd}}
                        </p>
                        <!-- 方案形态 -->
                        <p class="Planform">
                            {{printm.jjfa_faxt}}
                        </p>
                        <!-- 收入 -->
                        <p class="income">
                            {{printm.cwrz_sr}}
                        </p>
                        <!-- 出让股份 -->
                        <p class="shares">
                            {{printm.cwrz_crgf}}
                        </p>
                        <!-- 融资 -->
                        <p class="financing">
                            {{printm.cwrz_rz}}
                        </p>
                        <!-- 涉及行业 -->
                        <p class="industry">
                            {{printm.scfx_sjhy}}
                        </p>
                        <!-- 目标客户 -->
                        <p class="customer"> 
                            {{printm.scfx_mbkh}}
                        </p>    
                        <!-- 市场价值 -->
                        <p class="market">
                            {{printm.scfx_scjz}}
                        </p>
                        <!-- 销售规划 -->
                        <p class="Salesplan">
                            {{printm.fzgh_xsgh}}
                        </p>
                        <!-- 产品规划 -->
                        <p class="Productplan">
                            {{printm.fzgh_cpgh}}
                        </p>
                        <!-- 竞争分析 -->
                        <p class="analysis">
                            {{printm.jzfx}}
                        </p>
                        <!-- 报告时间 -->
                        <p class="Reporttime">
                            {{printm.time}}
                        </p>
                    </div>
                    </div>
                </div>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import $ from 'jquery'
// import '@/utils/jQuery.print.js'
import '@/utils/jquery-1.4.4.min.js'
import '@/utils/jquery-migrate-1.2.1.min.js'
import '@/utils/jquery.jqprint-0.3.js'
export default {
    data() {
        return {
            dialogVisibleimg:false,
            dialogImageUrl:'',
            active:0,
            show:false,
            istrue:0,
            istrues:0,
            coForm:{},
            Form:{},
            ruleForm:{},
            Forms:{},
            uploadimage:this.$store.state.uploadimage,
            rules:{
                lyXqScore:[
                    { required: true, message: '请进行打分'},
                    {
                        pattern: /^([0-9]|10)$/,
                        message: "分值为0~10之间的数字值"
                    }
                ],
                lyMbScore:[
                    { required: true, message: '请进行打分'},
                    {
                        pattern: /^([0-9]|10)$/,
                        message: "分值为0~10之间的数字值"
                    }
                ],
                lyCxScore:[
                    { required: true, message: '请进行打分'},
                    {
                        pattern: /^([0-9]|10)$/,
                        message: "分值为0~10之间的数字值"
                    }
                ],
                lySpScore:[
                    { required: true, message: '请进行打分'},
                    {
                        pattern: /^([0-9]|10)$/,
                        message: "分值为0~10之间的数字值"
                    }
                ],
                lyKxScore:[
                    { required: true, message: '请进行打分'},
                    {
                        pattern: /^([0-9]|10)$/,
                        message: "分值为0~10之间的数字值"
                    }
                ],
                lyXyScore:[
                    { required: true, message: '请进行打分'},
                    {
                        pattern: /^([0-9]|10)$/,
                        message: "分值为0~10之间的数字值"
                    }
                ],
                sjMbscScore:[
                    { required: true, message: '请进行打分'},
                    {
                        pattern: /^([0-9]|10)$/,
                        message: "分值为0~10之间的数字值"
                    }
                ],
                sjGjywScore:[
                    { required: true, message: '请选择类型',trigger:'change'},
                ],
                sjJpfxScore:[
                    { required: true, message: '请进行打分'},
                    {
                        pattern: /^([0-9]|10)$/,
                        message: "分值为0~10之间的数字值"
                    }
                ],
                sjTdnlScore:[
                    { required: true, message: '请进行打分'},
                    {
                        pattern: /^([0-9]|10)$/,
                        message: "分值为0~10之间的数字值"
                    }
                ],
                remark:[
                    { required: true, message: '请输入评语内容',trigger:'blur'},
                ],
            },
            dialogVisibleForm:false,
            dialogVisible:false,
            innerVisible:false,
            dialogPrint:false,
            printm:{
                time:'',
                base:{},
            },
            account:[
                {},{},{},
            ],
            inform:{},
            teamList:[],
            listitem:[],
            typeList:[],
            pro_names:'',
            coverName:'',
        }
    },
    methods: {
        getapp(){
            this.id = this.$route.query.id
            this.$emit('func', this.id);
            this.axios.coursedetail({
                id:this.id,
                token:this.$store.state.token
            }).then(res=>{
                if(res.data.code==0){
                    this.inform = res.data.data.course
                    this.teamList = res.data.data.team
                    if(this.teamList.length!=0){
                        this.axios.coursepjtlist({
                            token:this.$store.state.token,
                            id:this.teamList[0].id
                        }).then(res=>{
                            if(res.data.code==0){
                                this.listitem = res.data.data
                                this.pro_id = this.listitem[0].id
                                if(this.listitem.length!=0){
                                    this.pro_id = this.listitem[0].id
                                    this.axios.coursepjtdetail({
                                        token:this.$store.state.token,
                                        id:this.listitem[0].id
                                    }).then(res=>{
                                        if(res.data.code==0){
                                            var array = []
                                            res.data.data.project.map(item=>{
                                                if(item.hasSelect==1){
                                                    array.push(item)
                                                }
                                            })
                                            this.typeList =array
                                            this.pro_names = this.listitem[0].name
                                            this.ruleForm = res.data.data.base
                                            this.Forms = res.data.data.base
                                        }else{
                                            this.typeList = []
                                        }
                                    }).catch(err=>{

                                    })
                                }
                            }else{
                                this.listitem = []
                            }
                        }).catch(err=>{

                        })
                    }
                }
            }).catch(err=>{

            })
        },
        // 项目基本信息
        Newadd(){
            this.active = 0
            this.dialogVisible = true
        },
        //开课
        classes(){
            this.axios.coursestart({
                token:this.$store.state.token,
                id:this.id
            }).then(res=>{
                if(res.data.code==0){
                    this.$message.success('已开课')
                    this.getapp()
                }else{
                    this.$message.info(res.data.message)
                }
            }).catch(err=>{

            })
        },
        // 打分确定
        ermine(ruleForm,Forms){
            if(this.active==0){
                this.$refs[ruleForm].validate((valid) => {
                    if (valid) {
                        this.axios.coursepjtscore({
                            token:this.$store.state.token,
                            id:this.ruleForm.id,
                            xq:this.ruleForm.lyXqScore,
                            mb:this.ruleForm.lyMbScore,
                            cx:this.ruleForm.lyCxScore,
                            sp:this.ruleForm.lySpScore,
                            kx:this.ruleForm.lyKxScore,
                            xy:this.ruleForm.lyXyScore,
                        }).then(res=>{
                            if(res.data.code==0){
                                this.$message.success('评分完成')
                                this.dialogVisible = false
                                this.getapp()
                            }else{
                                this.$message.info(res.data.message)
                            }
                        }).catch(err=>{

                        })
                    }
                })
            }else{
                this.$refs[Forms].validate((valid) => {
                    if (valid) {
                        this.axios.coursepjtscore({
                            token:this.$store.state.token,
                            id:this.Forms.id,
                            mbsc:this.Forms.sjMbscScore,
                            gjyw:this.ruleForm.sjGjywScore,
                            jpfx:this.ruleForm.sjJpfxScore,
                            tdnl:this.ruleForm.sjTdnlScore,
                        }).then(res=>{
                            if(res.data.code==0){
                                this.$message.success('评分完成')
                                this.dialogVisible = false
                                this.getapp()
                            }else{
                                this.$message.info(res.data.message)
                            }
                        }).catch(err=>{

                        })
                    }
                })
            }
            
        },
        //修改项目详情
        updateBtn(item){
            this.axios.projectccatadt({
                token:this.$store.state.token,
                id:this.pro_id,
                cId:item.contentId
            }).then(res=>{
                if(res.data.code==0){
                    this.coverName = item.name
                    this.Form = res.data.data
                    this.dialogVisibleForm = true
                }
            }).catch(err=>{

            })
        }, 
        // 获取打印数据
        printBox(){
            this.axios.managecourseprint({
                token:this.$store.state.token,
                id:this.pro_id
            }).then(res=>{
                if(res.data.code==0){
                    this.printm = res.data.data
                    this.account = res.data.data.base.avatar
                    var date = new Date()
                    var y = date.getFullYear();
                    var m = date.getMonth() + 1;
                    m = m < 10 ? '0' + m : m;
                    var d = date.getDate();
                    d = d < 10 ? ('0' + d) : d;
                    this.printm.time  = y + '/' + m + '/' + d;
                    console.log(this.printm.time)
                    this.dialogPrint =true 
                }
            }).catch(err=>{

            })
        },
        //结课
        session(){
            this.$confirm('结课后学生将无法进行提交，是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                closeOnClickModal:false
            }).then(() => {
                this.axios.courseend({
                    token:this.$store.state.token,
                    id:this.id
                }).then(res=>{
                    if(res.data.code==0){
                        this.$message.success('已结课')
                        this.getapp()
                    }else{
                        this.$message.info(res.data.message)
                    }
                }).catch(err=>{

                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消结课'
                });          
            });
        },
        exits(item){
           
            this.coForm = item
            this.coverName = item.name
            this.coForm.id = item.id
            this.innerVisible = true
        },
        comments(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.axios.coursepjtremark({
                        token:this.$store.state.token,
                        remark:this.coForm.remark,
                        id:this.coForm.id
                    }).then(res=>{
                        if(res.data.code==0){
                            this.innerVisible = false
                            this.getapp()
                        }else{
                            this.$message.info(res.data.message)
                        }
                    }).catch(err=>{

                    })
                }
            })
        },
        // 项目信息tab切换
        tabBox(val){
            this.active = val
        },
        //选择小组
        sroom(item,val){
            this.axios.coursepjtlist({
                token:this.$store.state.token,
                id:item.id
            }).then(res=>{
                if(res.data.code==0){
                    this.listitem = res.data.data
                    this.istrue = val
                }else{
                    this.listitem = []
                }
            }).catch(err=>{

            })
        },
        //选择项目
        srooms(item,val){
            this.istrues = val
            this.pro_id = item.id
            this.axios.coursepjtdetail({
                token:this.$store.state.token,
                id:item.id
            }).then(res=>{
                if(res.data.code==0){
                    var array = []
                    res.data.data.project.map(item=>{
                        if(item.hasSelect==1){
                            array.push(item)
                        }
                    })
                    this.typeList =array
                    this.pro_names = item.name
                    this.ruleForm = res.data.data.base
                    this.Forms = res.data.data.base
                    this.show = false
                }
            }).catch(err=>{

            })
        },
        //返回上一级
        back(){
            this.$router.push({name:'Lecturerend'})
        },
        // 鼠标移入
        mouseover(){
            this.show = true
        },
        //鼠标移出
        mouseout(){
            this.show = false
        },
        handleClose(done){

        },
        // 关闭弹窗
        closei(){
            this.dialogVisible = false
            this.dialogVisibleForm = false
            this.innerVisible = false
        },
        closeis(){
            this.dialogPrint = false
            this.dialogRadarmap = false
        },
        // 打印
        printTemplate() {
            $('#print').jqprint({

            })
		},
        zoomin(file) {
            this.dialogImageUrl = file;
            this.dialogVisibleimg = true;
        },
    },
    mounted() {
        this.getapp()
    },
}
</script>
<style scoped>
@import './../../assets/css/teacher/Detailsclassroom.css';
</style>
<style>
/* 打印弹窗 */
.print_popup .el-dialog__header{
    padding: 0;
}
.print_popup .el-dialog{
    margin-top: 3vh!important;
    max-height: 90vh;
}
.sroom_right_content .el-row{
    height: 100%;
}
.sroom_right_top .el-button{
    padding: 12px 12px;
}
/* 项目信息弹窗 */
.Details_Pop .el-dialog{
    padding: 25px;
}
.Details_Pop .el-form-item{
    margin-bottom: 15px;
}
.Details_Pop .el-dialog__header{
    padding: 0;
    /* padding-bottom: 15px; */
    /* border-bottom: 2px solid #e5e5e5; */
}
.Details_Pop .el-dialog__body{
    padding: 0px 0px 20px;
}
.Details_Pop .el-form-item__label{
    line-height: 30px;
    font-size: 14px;
    font-weight: bold;
    color: #797979;
}
.Details_Pop .el-radio-group{
    width: 100%;
}
.Details_Pop .el-dialog__footer{
    text-align: center;
    padding:0
}
.Details_Pop .el-row{
    padding: 30px 20px 0;
}
/* 编辑弹窗 */
.editor_Popup .el-dialog{
    margin-top: 10vh!important;
}
.editor_Popup .el-dialog__header{
    background: #409eff;
    padding: 0 15px;
    height: 40px;
    line-height: 40px;
}
.editor_Popup .el-dialog__body{
    max-height: 60vh;
    overflow-y: auto;
}
.editor_Popup .el-form-item__label{
    line-height: 30px;
    font-size: 14px;
    font-weight: bold;
    color: #797979;
}
.editor_Popup .el-dialog__title{
    color: #fff;
    font-size: 16px;
    line-height: 40px;
}
.editor_Popup .el-dialog__footer{
    text-align: center;
}
.editor_Popup .el-textarea__inner{
    resize: none;
}
.editor_Popup .avatar-uploader{
    position: relative;
    font-size: 14px;
    display: inline-block;
    width: 100%;
    line-height: 1;
}
.editor_Popup .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.editor_Popup .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}
.editor_Popup .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 148px;
    height: 148px;
    line-height: 148px;
    text-align: center;
}
.editor_Popup .avatars {
    width: 148px;
    height: 148px;
    display: block;
    position: relative;
}
.avatars img{
    width: 100%;
    height: 100%;
}
.avatars_div:hover{
    opacity: 1;
}
.avatars_div{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    cursor: default;
    text-align: center;
    color: rgb(255, 255, 255);
    opacity: 0;
    font-size: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 0.3s ease 0s;
    display: flex;
    align-items: center;
    justify-content: center;
}
.avatars_div i {
    cursor: pointer;
}
.editor_Popup .img_form .el-form-item__label{
    width: 120px;
}
.editor_Popup .img_form .avatar-uploader{
    display: block;
}
</style>